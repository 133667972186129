import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router'
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { backendDateFormat, fieldDateFormat, assetsType } from '../../../constants';
import { Checkbox, Radio, RadioGroup, FormControl, FormControlLabel, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress, Chip } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus, getLocalStorageInfo, checkPermission } from '../../utils_v2';
import InfoIcon from '@material-ui/icons/Info';
import ServerErrorModal from "./ServerErrorModal";
import { Edit, Info } from "@material-ui/icons";
import AddTemplate from "./AddTemplate";
import { DeletePopUp, PageLoader } from "../../shared_elements";
import { jetStreamInstance } from "../../../shared/components";
const assemblyObj = {
    major_assembly_type: '',
    asset_type: null,
    serial_number: '',
    part_number: '',
    date_of_manufacture: null,
    position: null,
    country: '',
    lease_status: null,
    lessee: null,
    lessor_name: null,
    owner: null,
    same_operator_lessee: false,
    operator: null,
    match_csn_to_llp: false,
    titled_aircraft: null,
    other_aircraft_msn: '',
    is_assigned: false,
    on_wing_status: true,
    off_wing_status:0,
    is_billable:false
}
const WingStatusCard = ({ onFieldChange, error, onResetError, formData, offWingOptions, pltConstants, recordsPermission }) => {
    return (
        <>
            <Grid item md={6}>
                <Autocomplete
                    disableClearable
                    options={[{ label: 'On Wing', value: true }, { label: 'Off Wing', value: false }]}
                    getOptionLabel={option => option.label}
                    name="on_wing_status"
                    value={formData?.on_wing_status ? { label: 'On Wing', value: true } : { label: 'Off Wing', value: false }}
                    onChange={(e, value) => { onFieldChange('on_wing_status', value !== null ? value.value : null); onResetError('on_wing_status') }}
                    renderInput={params => <TextField required {...params} error={error?.on_wing_status} helperText={error?.on_wing_status || ''} label="Wing Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                />
            </Grid>
            {formData?.on_wing_status == false?
                <Grid item md={6}>
                    <Autocomplete
                        disableClearable
                        options={pltConstants.filter((optionItem) => optionItem.type === 'off_wing_status')}
                        getOptionLabel={option => option.label}
                        name="off_wing_status"
                        value={formData?.off_wing_status || null}
                        onChange={(e, value) => { onFieldChange('off_wing_status', value); onResetError('off_wing_status') }}
                        renderInput={params => <TextField required error={error?.off_wing_status} helperText={error?.off_wing_status || ''} {...params}
                            label={<span>Off Wing Condition
                                <Tooltip title={
                                    <div style={{ width: '220px' }}>
                                        Assembly can be "On Wing" if the off wing condition is "Off Wing Serviceable" only.
                                    </div>}
                                    arrow>
                                    <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                </Tooltip>
                            </span>}
                            margin="normal" fullWidth InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }} variant='outlined' />}
                    />
                </Grid>
          : null}
        </>
    )
}
const AssemblyLinkStatus = ({ params, formData, assetsOption, offWingOptions, onFieldChange, error, onResetError, assetType, pltConstants }) => {
    return (
        <div className="assembly-link-status">
            <div className="titled-asset">
                <div className="yellow-card">
                    <Grid container spacing={1} alignItems="center" alignContent="center">
                        <Grid item md={4}>
                            {(() => {
                                let options = [{ asset_name: 'Stand-alone', asset_type: 1, id: null, slug: null }, ...assetsOption];
                                return (
                                    <Autocomplete
                                        disabled={!formData?.asset_type && [2, 3].includes(assetType) ? true : false}
                                        options={options}
                                        getOptionLabel={option => option.asset_name}
                                        name="titled_aircraft"
                                        value={formData?.titled_aircraft || null}
                                        onChange={(e, value) => { onFieldChange('titled_aircraft', value); onResetError('titled_aircraft') }}
                                        renderInput={params => <TextField className="input-yellow-bg" required {...params} error={error?.titled_aircraft} helperText={error?.titled_aircraft || ''} label="Titled MSN" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                )
                            })()}
                        </Grid>
                        {formData?.titled_aircraft?.id === null ?
                            <Grid item md={4}>
                                <TextField
                                    name='other_aircraft_msn'
                                    label='Other MSN'
                                    value={formData?.other_aircraft_msn || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('other_aircraft_msn', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid> : null
                        }
                    </Grid>
                    {formData?.titled_aircraft?.asset_name ?
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                {formData?.titled_aircraft?.slug && (formData?.titled_aircraft?.slug === params.aircraft_slug) ?
                                    <FormControlLabel value="is_assigned" control={<Checkbox checked={formData.is_assigned} onChange={(e) => onFieldChange('is_assigned', e.target.checked)} size='small' color="primary" />} label={
                                        <span style={{display:'flex', alignItems:'center'}}>Assign to this MSN
                                        <Tooltip title='Assembly listed on CTA or following lease amendments' arrow>
                                            <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                        </Tooltip>
                                    </span>}
                                    /> :
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="position" name="position" defaultValue="is_fitted">
                                            <FormControlLabel value="is_assigned" control={<Radio checked={formData.is_assigned === false} onChange={(e) => onFieldChange('is_assigned', false)} color="primary" size="small" />} label={
                                                 <span style={{display:'flex', alignItems:'center'}}>Fitted
                                                 <Tooltip title='Physical location of the installed assembly' arrow>
                                                     <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                                 </Tooltip>
                                             </span>
                                            } />
                                            <FormControlLabel value="is_assigned" control={<Radio checked={formData.is_assigned === true} onChange={(e) => onFieldChange('is_assigned', true)} color="primary" size="small" />} label={
                                                   <span style={{display:'flex', alignItems:'center'}}>Assigned
                                                   <Tooltip title='Assembly listed on CTA or following lease amendments' arrow>
                                                       <Info style={{fontSize:'13px', marginLeft:'3px'}} />
                                                   </Tooltip>
                                               </span>
                                            } />
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </Grid>
                            {formData?.titled_aircraft?.slug === params?.aircraft_slug ?
                                <WingStatusCard
                                pltConstants={pltConstants}
                                    onFieldChange={onFieldChange}
                                    error={error}
                                    onResetError={onResetError}
                                    formData={formData}
                                    offWingOptions={offWingOptions}
                                /> : null
                            }
                            {formData?.titled_aircraft?.slug !== params?.aircraft_slug && formData?.is_assigned !== false ?
                                <WingStatusCard
                                pltConstants={pltConstants}
                                    onFieldChange={onFieldChange}
                                    error={error}
                                    onResetError={onResetError}
                                    formData={formData}
                                    offWingOptions={offWingOptions}
                                /> : null
                            }
                        </Grid> : null
                    }
                </div>
            </div>
        </div>
    )
}
const AddAssembly = ({ params, assetInfoData, addEditAssembly, toggleModalFn, getResponseBack, recordsPermission }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [assembly, setAssembly] = useState(assemblyObj);
    const [engineTypes, setEngineTypes] = useState([]);
    const [apuTypes, setApuTypes] = useState([]);
    const [lessees, setLessees] = useState([]);
    const [operators, setOperators] = useState([]);
    const [lessors, setLessors] = useState([]);
    const [assets, setAssets] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [subAssemblyList, setSubAssemblyList] = useState([]);
    const [error, setError] = useState({});
    const [serverError, setErrorModal] = useState({ modal: false });
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    const [noRecordsPermission, setNoRecordsPermission] = useState({modal:false});
    const [defaultTemplate, setDefaultTemplate] = useState({});
    const [templateData, setTemplateData] = useState(null);
    const [templatPayload, setTemplatPayload] = useState(null);
    const [spreadedFolders, setSpreadedFolders] = useState([]);
    const [uuid, setUuid] = useState({});

    useEffect(() => {
        getEngineTypes();
        getUuid(assetInfoData?.slug)
        getLessees();
        getOperators();
        getLessors();
        getApuTypes();
        getPlatformConstants();
        if (addEditAssembly?.data?.type === 5) {
            getAssets({ propeller_aircrafts: true });
        }
        else {
            getAssets();
        }
    }, []);
    const getAssets = (query) => {
        globalGetService('console/list/?asset_type=1&dropdown=true', assetInfoData?.status?.value == 5 ? { ...query, archive_status: true } : query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssets(response.data.data.asset)
                }
            })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`, { aircraft_type: assetInfoData.aircraft_type.id, lite: true })
            .then(response => {
                if (checkApiStatus(response)) {
                    setEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getApuTypes = () => {
        globalGetService('console/apu-types/', { aircraft_type: assetInfoData.aircraft_type.id })
            .then(response => {
                if (checkApiStatus(response)) {
                    setApuTypes(response.data.data.apu_types);
                }
            })
    }

    const getLessees = () => {
        globalGetService(`console/lessees/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getOperators = () => {
        globalGetService(`console/operators/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOperators(response.data.data.operators)
                }
            })
    }
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_status', 'asset_sub_status', 'engine_position', 'lg_position', 'off_wing_status'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }
    const onFieldChange = (key, value) => {
        setAssembly({ ...assembly, [key]: value });
        if (key === 'on_wing_status') {
            setAssembly(prevState => ({ ...prevState, off_wing_status: 0 }));
        }
        if (key === 'asset_type' || key === 'serial_number') {
            if (key === 'asset_type' && value && assembly?.serial_number?.trim()?.length) {
                checkAssemblyValidate({
                    validate_for: 'serial_number',
                    major_assembly_type: assetsType[addEditAssembly.data.type],
                    serial_number: assembly?.serial_number,
                    asset_type: value
                })
            }
            if (key === 'asset_type' && [2, 3].includes(addEditAssembly?.data?.type)) {
                if (addEditAssembly?.data?.type == 2) {
                    getAssets({ linked_engine_type: value?.id })
                } else {
                    getAssets({ linked_apu_type: value?.id })
                }
                setAssembly(prevState => ({ ...prevState, titled_aircraft: null }));
            }
            if (key === 'serial_number' && value?.trim()?.length && assembly?.asset_type) {
                checkAssemblyValidate({
                    validate_for: 'serial_number',
                    major_assembly_type: assetsType[addEditAssembly.data.type],
                    serial_number: value,
                    asset_type: assembly.asset_type
                })
            }
        }
        if (key === 'position' || key === 'asset_type' || key=='serial_number') {
            setAssembly(prevState=>({...prevState, add_template:null}));
            setDefaultTemplate({})
        }
        if (key === 'position' && value) {
            checkAssemblyValidate({
                validate_for: 'position',
                major_assembly_type: assetsType[addEditAssembly.data.type],
                position: value
            })
            if (fetchSubAssembly) {
                getLgSubAssembly(value, assetInfoData?.aircraft_type?.id)
            }
        }
        if (key === 'titled_aircraft' || key === 'is_assigned') {
            if (key === 'titled_aircraft') {
                setAssembly(prevState=>({...prevState, on_wing_status:value?.slug ? prevState?.on_wing_status : true , is_assigned: value?.slug === params.aircraft_slug}));
                checkAssemblyValidate({
                    validate_for: 'titled_aircraft',
                    major_assembly_type: assetsType[addEditAssembly.data.type],
                })
            }
            else{
                setAssembly(prevState=>({...prevState, on_wing_status: prevState?.is_assigned === false ? true : prevState?.is_assigned}));
            }

        }
        if (key === 'on_wing_msn') {
            if (value && value?.value) {
                checkAssemblyValidate({
                    validate_for: 'on_wing_msn',
                    major_assembly_type: assetsType[addEditAssembly.data.type],
                })
            }
        }
        if(key === 'add_template'){
            if(!value){
                setDefaultTemplate({})
            }
            setAssembly({ ...assembly, [key]: value });
        }
    }
    const checkAssemblyValidate = (validateObj) => {
        globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/validate-major-assembly/`, validateObj)
            .then(response => {
                if (!checkApiStatus(response)) {
                    let errorKey = validateObj.validate_for;
                    setError({
                        ...error,
                        [errorKey]: response.data.message
                    })
                }
            });
    }
    const onAddAssembly = (type,data) => {
        let subAssemblyReqCond = assetInfoData?.aircraft_type?.name !== "ATR72-600"
        let validationInputs = {
            serial_number: assembly?.serial_number?.trim()?.length ? '' : `Please enter Serial Number ${addEditAssembly?.data?.type === 2 ? 'ESN ' : 'Serial Number'}`,
            part_number: [3, 4].includes(addEditAssembly?.data?.type) ? assembly?.part_number?.trim()?.length ? '' : 'Please enter Part Number' : '',
            asset_type: [2, 3].includes(addEditAssembly?.data?.type) ? assembly?.asset_type ? '' : `Please select ${addEditAssembly?.data?.type === 2 ? 'Engine ' : 'APU '} Type` : '',
            position: addEditAssembly?.data?.type !== 3 ? assembly?.position ? '' : `Please select ${addEditAssembly?.data?.type === 4 ? 'LG Position' : 'Position'}` : '',
            date_of_manufacture: moment(assembly?.date_of_manufacture).isValid() ? moment(assembly.date_of_manufacture).isValid() && moment(assembly?.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture can't be future date" : 'Please enter Date of Manufacture',
            country: assembly?.country?.trim()?.length ? '' : 'Please enter Country',
            lease_status: assembly?.lease_status ? '' : 'Please select Lease Status',
            sub_status: getLocalStorageInfo()?.defaultLessor?.id === 242 ? assembly.sub_status ? '' : 'Please select Sub Status' : '',
            lessor_name: assembly?.lessor_name ? '' : `Please select ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`,
            owner: assembly?.owner ? '' : `Please select ${jetStreamInstance?'Owner/SPV':'Owner'}`,
            sub_assembly: subAssemblyList?.length > 0 ? assembly?.sub_assembly ? '' :subAssemblyReqCond? 'Please select Sub Assembly':'' : '',
            lessee: assembly?.lease_status?.value === 1 ? assembly?.lessee ? '' : 'Please select Lessee' : '',
            operator: assembly?.lease_status?.value === 1 && assembly?.same_operator_lessee === false ? assembly?.operator ? '' : 'Please select Operator' : '',
            titled_aircraft: assembly?.titled_aircraft ? '' : 'Please select Titled MSN',
            off_wing_status :assembly?.on_wing_status == false ? assembly?.off_wing_status ? '':'Please select Off Wing Condition':''
        }
        if (assembly?.lease_status?.value === 1 && assembly?.same_operator_lessee === false && assembly.lessee && assembly.operator) {
            if (assembly.lessee.name === assembly.operator.name) {
                validationInputs = {
                    ...validationInputs,
                    operator: 'Please select different Operator'
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            if( !recordsPermission?.settings?.records_template?.includes('C') && getLocalStorageInfo()?.is_records && (noRecordsPermission?.modal == false) ){
                setNoRecordsPermission({modal:true})
            }
            else{
            let payload = Object.assign({}, assembly)
            payload = {
                ...payload,
                major_assembly_type: assetsType[addEditAssembly.data.type]
            }
            if (assembly?.titled_aircraft?.id === null) {
                payload = {
                    ...payload,
                    titled_aircraft: null
                }
            }
            let dataCopy = Object.assign({}, assembly)
                if(type =='notify_email'){
                    payload = {
                        ...payload,
                        notify_email: true
                    }
                }
            setLoading(true);
            globalPostService(`console/v2/${params.type}/${params.aircraft_slug}/major-assemblies/`, payload)
                .then(response => {
                    setNoRecordsPermission({modal:false})
                    if (checkApiStatus(response)) {
                        if(dataCopy?.add_template === true){
                            addTemplateonSubmit(templateData?templateData :defaultTemplate, response.data.data)
                        }
                        else{
                            toggleModalFn();
                        }
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    } else if (response.data.statusCode === 422) {
                        setErrorModal({ modal: true, data: response })
                    } else {

                    }
                    setLoading(false);
                })
        } 
    }
        else {
            setError(validationInputs);
        }
    }
    const getLgSubAssembly = (position, aircraftId) => {
        globalGetService(`console/lg_sub_assemblies_position/?position=${position?.value}&aircraft_type_id=${aircraftId}`
        ).then((response) => {
            if (checkApiStatus(response)) {
                setSubAssemblyList(response.data.data)
            }
        });
    }
    const getUuid = (data) => {
        setLoading(true)
        globalGetService(`console/aircraft/${data}/records-detail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setUuid(response.data.data?.record_folders?.technical_records)
                }
            })
    }
    const addTemplateonSubmit =(templateData, assetData )=>{
        let payload ={}
        if(!templateData?.asset && !templateData?.asset_type){
            payload={
                ...payload,
                ...templatPayload,
                majorAssembly:true,
                recordsNewFolderName: "",
                recordsParentFolder:uuid
            }
        }
         payload ={
            ...payload, ...templateData,
            majorAssemblyAsset: assetData,
        }
        if (!templateData?.asset && !templateData?.asset_type) {
            payload = {
                ...payload,
                folderDetails: spreadedFolders,
                asset_model_type:templatPayload?.asset_model_type
            }
        }
        delete payload['selectedTemplate']
        setLoading(true)
        globalPostService(`records/v2/lessor-folder-template/apply-template/${payload.templateDetails?.id}/`, payload)
        .then(response => {
            if(checkApiStatus(response)){
                    setLoading(false)
                    setTimeout(()=>{
                        window.location.reload()
                    },200)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            });
    }
    const fetchTemplate =(flag)=>{
        if(flag){
            let payload={}
            payload= {
                ...payload,
                asset_type: 1,
                asset: assetInfoData,// main asset
                asset_model_type:assembly?.asset_type?.id,
                majorAssemblyAssetType:addEditAssembly?.data?.type,
                majorAssemblyAsset:{}, 
                position: assembly?.position,
                is_major_assembly:true,
                serial_number:assembly?.serial_number
            }
            setPageLoader(true)
            globalPostService(`records/v2/get-lessor-folder-template/`,payload)
            .then(response=>{
                setPageLoader(false)
                setTemplatPayload(payload)
                if(checkApiStatus(response)){
                    if(response.data.data?.templateDetails){
                        let responseData = response.data?.data?.folderDetails || []
                        responseData.map(item=> getFoldersOutFn(item, item?.is_checked, 'newItem'))
                        setDefaultTemplate(response.data.data)
                    }
                    else{
                        setAddTemplateModal({flag:true, data:{} ,maType:addEditAssembly.data})
                    }
                }
                else {
                    setAddTemplateModal({ flag: true, data: {}, apiCall: 'apicall' })
                }
            })
        }
        else{
            setDefaultTemplate({})
        }
    }
    const getTemplateDetailsFn =()=>{
        setAddTemplateModal({flag:true, data:defaultTemplate })
    }
    const saveTemplateData =(data)=>{
        setTemplateData(data)
    }
    const checkTemplateData =(value) =>{
        if(!value){
            setTemplateData({}) 
        }
    }
    const checkForDefaultTemp =()=>{
        if( !defaultTemplate?.templateDetails){
            onFieldChange('add_template',false)
        }
    }
    const getFoldersOutFn =(folder, checked, newItem)=>{ 
        let initialData = [...spreadedFolders]
        const updatedCheckedFolders = initialData;
        const toggleCheck = (folder, isChecked) => {
            const folderIndex = updatedCheckedFolders.findIndex(item => item.folder_name === folder.folder_name && item?.parent_folder[0] ==folder?.parent_folder[0] ); 
            const folderData = {
                is_checked: isChecked,
                level: folder?.level,
                parent_folder: folder?.parent_folder,
                naming_conventions: folder?.naming_conventions,
                folder_name: folder?.folder_name,
                childrens: folder?.childrens
            };

            if (folderIndex === -1) {
                updatedCheckedFolders.push(folderData);
            } else {
                updatedCheckedFolders[folderIndex] = folderData;
            }
            folder.childrens.forEach(child => toggleCheck(child, child?.is_checked));
        };
        toggleCheck(folder, checked);
        if(( spreadedFolders?.length < 1) && newItem){
            setSpreadedFolders(prevState=> ([...prevState, ...updatedCheckedFolders]))
        }
        else{
            setSpreadedFolders(updatedCheckedFolders);
        }

    }
    const fetchSubAssembly = assetInfoData?.aircraft_type?.default_lg_position === false && (addEditAssembly?.data?.type == 4);
    let subAssemblyReqCond = assetInfoData?.aircraft_type?.name !== "ATR72-600"
    console.log('spreadedFolders',spreadedFolders);
    return (
        <>
        {pageLoader ? <PageLoader /> : null}
            <Dialog
                open={addEditAssembly.modal}
                onClose={toggleModalFn}
                // style={addTemplateModal? {display:"none"}:{}}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Add {addEditAssembly.data.label} Assembly
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className="add-assembly-modal">
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                <TextField
                                    required
                                    name='serial_number'
                                    label={addEditAssembly?.data?.type === 2 ?
                                        <div style={{ display: "flex" }}>ESN
                                            <Tooltip title='Engine Serial Number' arrow>
                                                <span > <InfoIcon /></span>
                                            </Tooltip>
                                        </div>
                                        : 'Serial Number'}
                                    value={assembly?.serial_number || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('serial_number', e.target.value)}
                                    error={error?.serial_number}
                                    helperText={error?.serial_number || ''}
                                    onFocus={() => setError({ ...error, 'serial_number': '' })}
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto', display: 'flex' } }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            {addEditAssembly?.data?.type !== 2 ?
                                <Grid item md={4}>
                                    <TextField
                                        required={[3, 4].includes(addEditAssembly?.data?.type)}
                                        name='part_number'
                                        label='Part Number'
                                        value={assembly?.part_number || ''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) => onFieldChange('part_number', e.target.value)}
                                        error={error?.part_number}
                                        helperText={error?.part_number || ''}
                                        onFocus={() => setError({ ...error, 'part_number': '' })}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 25 }}
                                        variant='outlined'
                                    />
                                </Grid> : null
                            }
                            {addEditAssembly?.data?.type === 2 ?
                                <Grid item md={4}>
                                    <Autocomplete
                                        options={engineTypes}
                                        getOptionLabel={option => option.name}
                                        name="asset_type"
                                        value={assembly?.asset_type || null}
                                        onChange={(e, value) => { onFieldChange('asset_type', value); setError({ ...error, 'asset_type': '' }) }}
                                        renderInput={params => <TextField required error={error?.asset_type} helperText={error?.asset_type || ''} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            {addEditAssembly?.data?.type === 3 ?
                                <Grid item md={4}>
                                    <Autocomplete
                                        options={apuTypes}
                                        getOptionLabel={option => option.name}
                                        name="asset_type"
                                        value={assembly?.asset_type || null}
                                        onChange={(e, value) => { onFieldChange('asset_type', value); setError({ ...error, 'asset_type': '' }) }}
                                        renderInput={params => <TextField required error={error?.asset_type} helperText={error?.asset_type || ''} {...params} label="APU Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid> : null
                            }
                            {addEditAssembly?.data?.type !== 3 ?
                                <Grid item md={4}>
                                    {(() => {
                                        let lgPositions = getLocalStorageInfo()?.defaultLessor?.id === 442 ? pltConstants.filter(optionItem => optionItem.type === 'lg_position' && (optionItem.remarks === 'default' || optionItem.remarks === '442')) : pltConstants.filter(optionItem => optionItem.type === 'lg_position' && optionItem.remarks === 'default');
                                        return (
                                            <Autocomplete
                                                options={addEditAssembly.data.type === 4 ? lgPositions : pltConstants.filter(optionItem => optionItem.type === 'engine_position')}
                                                getOptionLabel={option => option.label}
                                                name="position"
                                                value={assembly?.position || null}
                                                onChange={(e, value) => { onFieldChange('position', value); setError({ ...error, 'position': '' }); setAssembly(prevState => ({ ...prevState, sub_assembly: null })); setSubAssemblyList([]) }}
                                                renderInput={params => <TextField required error={error?.position} helperText={error?.position || ''} {...params} label={`${addEditAssembly?.data?.type === 4 ? 'LG Position' : 'Position'}`} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                            />
                                        )
                                    })()}

                                </Grid> : null
                            }
                            {fetchSubAssembly && addEditAssembly?.data?.type === 4 ?
                                <Grid item md={4}>
                                    <Autocomplete
                                        options={subAssemblyList}
                                        getOptionLabel={option => option.name}
                                        name="sub_assembly"
                                        value={assembly?.sub_assembly || null}
                                        onChange={(e, value) => { onFieldChange('sub_assembly', value) }}
                                        renderInput={params => <TextField {...params} required={subAssemblyList?.length > 0 && subAssemblyReqCond} error={error?.sub_assembly} helperText={error?.sub_assembly || ''} label='Sub Assembly' margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                                : null
                            }
                            <Grid item md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        margin="normal"
                                        name="date_of_manufacture"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(40, 'years')}
                                        value={assembly?.date_of_manufacture || null}
                                        error={error?.date_of_manufacture}
                                        helperText={error?.date_of_manufacture || ''}
                                        onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat)); setError({ ...error, 'date_of_manufacture': '' }) }}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    required
                                    name='country'
                                    label='Country'
                                    value={assembly?.country || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('country', e.target.value)}
                                    error={error?.country}
                                    helperText={error?.country || ''}
                                    onFocus={() => setError({ ...error, 'country': '' })}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={getLocalStorageInfo()?.defaultLessor?.id !== 442 ? (pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === null).filter(item => item?.value !== 5)) : pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.value !== 5 && item?.value !== 3)}
                                    getOptionLabel={option => option.label}
                                    name="lease_status"
                                    value={assembly?.lease_status || null}
                                    onChange={(e, value) => { onFieldChange('lease_status', value); setError({ ...error, 'lease_status': '' }) }}
                                    renderInput={params => <TextField required error={error?.lease_status} helperText={error?.lease_status || ''} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={pltConstants.filter(item => item?.type === 'asset_sub_status')}
                                    getOptionLabel={option => option.label}
                                    name="sub_status"
                                    value={assembly?.sub_status || null}
                                    onChange={(e, value) => { onFieldChange('sub_status', value); setError({ ...error, 'sub_status': '' }) }}
                                    renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242} error={error?.sub_status} helperText={error?.sub_status || ''} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={lessors}
                                    getOptionLabel={option => option.name}
                                    name="lessor_name"
                                    value={assembly?.lessor_name || null}
                                    onChange={(e, value) => { onFieldChange('lessor_name', value); setError({ ...error, 'lessor_name': '' }) }}
                                    renderInput={params => <TextField required error={error?.lessor_name} helperText={error?.lessor_name || ''} {...params} label={jetStreamInstance?"Lessor/Holdco":"Lessor"} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={lessors}
                                    getOptionLabel={option => option.name}
                                    name="owner"
                                    value={assembly?.owner || null}
                                    onChange={(e, value) => { onFieldChange('owner', value); setError({ ...error, 'owner': '' }) }}
                                    renderInput={params => <TextField required error={error?.owner} helperText={error?.owner || ''} {...params} label={jetStreamInstance?"Owner/SPV":"Owner"} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            {assembly?.lease_status?.value === 1 ?
                                <>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            options={lessees}
                                            getOptionLabel={option => option.name}
                                            name="lessee"
                                            value={assembly?.lessee || null}
                                            onChange={(e, value) => { onFieldChange('lessee', value); setError({ ...error, 'lessee': '' }) }}
                                            renderInput={params => <TextField required error={error?.lessee} helperText={error?.lessee || ''} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disableClearable
                                            options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                            getOptionLabel={option => option.label}
                                            name="same_operator_lessee"
                                            value={assembly?.same_operator_lessee ? { label: 'Yes', value: true } : { label: 'No', value: false }}
                                            onChange={(e, value) => { onFieldChange('same_operator_lessee', value.value); setError({ ...error, 'operator': '' }) }}
                                            renderInput={params => <TextField required {...params} label="Operator is same as Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Autocomplete
                                            disabled={assembly?.same_operator_lessee}
                                            options={lessees}
                                            getOptionLabel={option => option.name}
                                            name="operator"
                                            value={assembly?.same_operator_lessee ? assembly.lessee : assembly?.operator || null}
                                            onChange={(e, value) => { onFieldChange('operator', value); setError({ ...error, 'operator': '' }) }}
                                            renderInput={params => <TextField required={!assembly.same_as_lessee} error={error?.operator} helperText={error?.operator || ''} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                </> : null
                            }
                            <Grid item md={12}>
                                {addEditAssembly?.data?.type === 2 ?
                                    <FormControlLabel control={<Checkbox size="small" color='primary' checked={assembly?.match_csn_to_llp} onChange={(e) => onFieldChange('match_csn_to_llp', e.target.checked)} name="match_csn_to_llp" />} label={<p style={{ fontSize: '13px' }}>Match LLPs with Engine Utilization</p>} />
                                    : null
                                }{
                                    getLocalStorageInfo()?.user?.permission?.contracts ?
                                    <FormControlLabel control={<Checkbox size="small" color='primary' checked={assembly?.is_billable} onChange={(e) => onFieldChange('is_billable', e.target.checked)} name="is_billable" />} label={<p style={{ fontSize: '13px' }}> 
                                        <span> Is Billable asset</span>
                                            <Tooltip title='Please click on “Is Billable” to mark this major assembly asset as billable for this MSN’s lease.' arrow>
                                                <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </p>} />
                                : null}
                            </Grid>
                            <Grid item md={12}>
                                <AssemblyLinkStatus
                                pltConstants={pltConstants}
                                    assetType={addEditAssembly?.data?.type}
                                    params={params}
                                    error={error}
                                    onResetError={(key) => setError({ ...error, [key]: '' })}
                                    formData={assembly}
                                    onFieldChange={onFieldChange}
                                    assetsOption={assets}
                                />
                            </Grid>
                            {
                                getLocalStorageInfo()?.is_records ?
                                    <Grid item md={12}>
                                        <FormControlLabel control={<Checkbox disabled={( ([2,3].includes(addEditAssembly?.data?.type) ? assembly?.asset_type : true) && assembly?.serial_number &&  (addEditAssembly?.data?.type == 2 ?assembly?.position: true ) ) ? false :true} size="small" color='primary' checked={assembly?.add_template ? true : false} onChange={(e) => {onFieldChange('add_template', e.target.checked); fetchTemplate(e.target.checked);checkTemplateData(e.target.checked) }} name="add_template" />} label={<p style={{ fontSize: '13px' }}>
                                            <span> Create Major Assembly Folder</span>
                                            <Tooltip title='Please click on “Create Major Assembly Folder” to choose a template and apply it to this asset’s Dataroom.' arrow>
                                                <Info style={{ fontSize: '13px', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </p>} />
                                        {
                                            defaultTemplate?.templateDetails && assembly?.add_template ?
                                            <Chip onDelete={getTemplateDetailsFn} deleteIcon={<Edit/>} variant="outlined" color="primary" label={defaultTemplate?.templateDetails?.name} size='small' onClick={()=>getTemplateDetailsFn()} />: null
                                        }
                                        <br/>
                                        <span className='notes-yellow'>
                                            {
                                                // permission?.settings?.records_template?.includes('C') ?
                                                `Add Serial Number${(addEditAssembly?.data?.type == 2 ?', Position': '' )} ${[2,3].includes(addEditAssembly?.data?.type) ? 'and Asset Type':''} to enable Creating Major Assembly Folders`
                                                // : 'You Do not have Records Template Access to enable this'
                                            }
                                        </span>
                                    </Grid>
                                    : null}
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onAddAssembly} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                serverError?.modal ?
                    <ServerErrorModal
                        serverError={serverError}
                        toggleModalFn={() => setErrorModal({ modal: false })}
                    /> : null
            }
             { 
                addTemplateModal?.flag ?
                <AddTemplate 
                templateData={templateData}
                setDefaultTemplate={setDefaultTemplate}
                templatPayload={templatPayload}
                defaultTemplate={defaultTemplate}
                submitWithTemplate={(data)=>{saveTemplateData(data);setAddTemplateModal(false)}}
                assetInfoData={assetInfoData}
                assemblyData={{assembly:assembly, asset_type:addEditAssembly?.data?.type}}
                addTemplateModal={addTemplateModal}
                toggleModalFn={()=>{setAddTemplateModal(false); checkForDefaultTemp() }}
                /> : null
            }
            {
                noRecordsPermission.modal ?
                    <DeletePopUp
                        modal={noRecordsPermission.modal}
                        title='Add Assembly'
                        confirmColor='primary'
                        confirmText='Save'
                        content={
                            <span>
                                Asset Creation Recquires Records Action, You do not have a Records Application Permission to Perform.
                                <div style={{ marginTop: '15px' }}>
                                    Are you sure, you want to Continue?
                                </div>
                            </span>}
                        toggleModalFn={() => toggleModalFn({ modal: false, data: null })}
                        deleteRecordFn={()=>onAddAssembly('notify_email')}
                    /> : null
            }
        </>
    )
}
export default withRouter(AddAssembly);
import React, { useState, useEffect } from "react";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormatShort } from '../../../constants';
import { TableCell, TableRow, Paper, Grid, CircularProgress, Button, TextField, Tooltip } from '@material-ui/core';
import { AutocompleteCreatable } from '../../shared_elements';
import { checkPermission } from '../../utils_v2';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from "../../../constants/regEx";
import { getPermissionKey } from "..";

export const EngineLlpList = ({ item, editField, onFieldChange, sheetError, setSheetError, onResetError, params, onDelete }) => {
    const [showMore, setShowMore] = useState({});
    const StatusCode = item.part_numbers_dropdown?.filter((option => option.part_number === item?.part_number))[0]?.status;
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const calculateWidth = (minWidth, maxWidth, content, minCount, maxCount) => {
        let width = 50;
        if (content?.length < minCount) {
            width = minWidth;
        } else if (content?.length > minCount) {
            width = maxWidth;
        }

        return `${width}px`;
    }
    return (
        <TableRow>
            <TableCell>
            {editField ?
                    <TextField
                        required
                        value={item?.module}
                        onChange={(e) => onFieldChange('module', e.target.value)}
                        //style={{ width: "80px" }}
                        variant="standard"
                        error={sheetError?.module ? true : false}
                        helperText={sheetError?.module || ''}
                        onFocus={() => setSheetError({ ...sheetError, 'module': '' })}
                        inputProps={{ maxLength: 15 }}
                    /> :
                <div>{item?.module || "--"}</div>
            }
            </TableCell>
            <TableCell>
                {editField ?
                    <TextField
                        required
                        value={item?.description}
                        onChange={(e) => onFieldChange('description', e.target.value)}
                        //style={{ width: "80px" }}
                        variant="standard"
                        error={sheetError?.description ? true : false}
                        helperText={sheetError?.description || ''}
                        onFocus={() => setSheetError({ ...sheetError, 'description': '' })}
                        inputProps={{ maxLength: 15 }}
                    /> :
                    item?.description && item?.description?.length > 25 ? (
                        <div>
                            {showMore[item?.field] ? (
                                <span> {item?.description}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                                </span>
                            ) : (
                                <span> {item.description.substring(0, 20)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) : (
                        <div>{item.description}</div> || '--'
                    )}
            </TableCell>
            <TableCell>{editField ?
                <>
                    {(() => {

                        let optionValue = item?.part_number && item?.part_numbers_dropdown?.length ? item.part_numbers_dropdown.find((pn) => pn.part_number === item.part_number) : null;
                        if ((optionValue === null || optionValue === undefined) && item.part_number) {
                            optionValue = { part_number: item.part_number }
                        }
                        let options = item?.part_number && item?.part_numbers_dropdown?.length ? item.part_numbers_dropdown.filter((option => option.part_number !== item?.part_number)) : []
                        return (
                            <AutocompleteCreatable
                                options={options}
                                paramsKey='part_number'
                                optionKey='part_number'
                                value={optionValue?.part_number || ''}
                                multiple={false}
                                onFieldChange={(e, paramsKey, newValue) => onFieldChange('part_number', newValue)}
                                autoCompleteWidth={"140px"}
                                disableClearable={true}
                                filterSelectedOption={true}
                                variant="standard"
                            />
                        )
                    })()}
                </>
                :
                <div>{item?.part_number || "--"}</div>}
                {StatusCode === 2 ? <span style={{ border: "1px solid #a78701", backgroundColor: "#fff1b6", fontSize: "12px", width: "120px", display: "block", fontFamily: "Conv_IBMPlexSans-Regular", borderRadius: "4px", padding: "2px 2px" }}>Note: LLP under approval process.</span> : ''}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "80px" }}
                    value={item?.serial_number}
                    inputProps={{ maxLength: 15 }}
                    error={sheetError?.serial_number ? true : false}
                    helperText={sheetError?.serial_number || ''}
                    onFocus={() => setSheetError({ ...sheetError, 'serial_number': '' })}
                    onChange={(e) => onFieldChange('serial_number', e.target.value)}
                /> :
                <div >{item?.serial_number || ''}</div>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    style={{ width: "80px" }}
                    value={item?.tsn}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                /> :
                <div >{item?.tsn ? (item?.tsn).toFixed(2) : '0'}</div>}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    error={sheetError?.csn ? true : false}
                    helperText={sheetError?.csn || ''}
                    style={{ width: "80px" }}
                    value={item?.csn}
                    onFocus={() => setSheetError({ ...sheetError, 'csn': '' })}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                /> :
                <div>{item?.csn ? (item?.csn) : '0'}</div>}
            </TableCell>
            <TableCell>
                {editField ?
                    <TextField
                        error={sheetError?.cycles_max_limit ? true : false}
                        helperText={sheetError?.cycles_max_limit || ''}
                        style={{ width: "80px" }}
                        value={item?.cycles_max_limit}
                        onFocus={() => setSheetError({ ...sheetError, 'cycles_max_limit': '' })}
                        onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('cycles_max_limit', e.target.value) : e.preventDefault()}
                    /> :
                    <>
                        <div>{item?.cycles_max_limit ? item?.cycles_max_limit : '0'}</div>
                        <p className="error-msg">{sheetError?.cycles_max_limit || ''}</p>
                    </>
                }
            </TableCell>
            <TableCell>
                <>
                    <div>{item?.cycles_remaining || '0'}</div>
                    <p className="error-msg">{sheetError?.cycles_remaining || ''}</p>
                </>
            </TableCell>
            <TableCell>{editField ? (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        style={{ width: "130px" }}
                        name="sheet_date"
                        format={fieldDateFormat}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        value={item?.sheet_date || null}
                        onChange={(data, value) => { onFieldChange('sheet_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('sheet_date') }}
                        error={sheetError?.sheet_date ? true : false}
                        helperText={sheetError?.sheet_date || ''}
                    />
                </MuiPickersUtilsProvider>
            ) : (
                moment(item?.sheet_date).isValid() ? moment(item?.sheet_date).format(displayDateFormatShort) : "--"
            )}
            </TableCell>
            <TableCell>{editField ?
                <TextField
                    inputProps={{ maxLength: 225 }}
                    value={item?.remarks}
                    onChange={(e) => onFieldChange('remarks', e.target.value)}
                    multiline
                    rows={2}
                /> :

                (item?.remarks && item?.remarks?.length > 25 ? (
                    <div style={{ width: calculateWidth('100', '142', item?.remarks, '25', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 20)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{item.remarks || '--'}</span>
                ))}
            </TableCell>
            {!editField && checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?

                <TableCell>
                    <ul className="list-inline">
                        <li className="list-inline-item hover-inline " onClick={onDelete}> <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip></li>
                    </ul>
                </TableCell> : null}
        </TableRow>
    )
}
